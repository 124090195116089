<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="640">
    <v-card>
      <v-card-title>
        <span class='text-h5'>{{ actionName }}</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container>
          <v-form ref='form' v-model='valid' lazy-validation :disabled='loading'>
            <v-row>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  店舗名
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  店舗の名前を20文字以内で入力してください。
                </p>
                <v-text-field
                  v-model='shop.name'
                  outlined
                  dense
                  validate-on-blur
                  :counter=20
                  :rules='[formRules.required, formRules.limit_length]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  平日営業時間
                  <span class='required-label'>必須</span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  平日の営業時間を 0:00 ~ 23:00 の範囲で入力してください。
                </p>
                <v-row>
                  <v-col cols='12' class='d-flex justify-space-between'>
                    <v-text-field
                      v-model.number='shop.openTime'
                      outlined
                      dense
                      validate-on-blur
                      type='number'
                      reverse
                      prefix=':00'
                      :rules='[formRules.time]'
                    ></v-text-field>
                    <p class='mx-2'>~</p>
                    <v-text-field
                      v-model.number='shop.viewCloseTime'
                      outlined
                      dense
                      validate-on-blur
                      type='number'
                      reverse
                      prefix=':00'
                      :rules='[formRules.time]'
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  土日祝日営業時間
                  <span class='required-label'>必須</span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  土日祝日の営業時間を 0:00 ~ 23:00 の範囲で入力してください。
                </p>
                <v-row>
                  <v-col cols='12' class='d-flex justify-space-between'>
                    <v-text-field
                      v-model.number='shop.holidayOpenTime'
                      outlined
                      dense
                      validate-on-blur
                      type='number'
                      reverse
                      prefix=':00'
                      :rules='[formRules.time]'
                    ></v-text-field>
                    <p class='mx-2'>~</p>
                    <v-text-field
                      v-model.number='shop.viewHolidayCloseTime'
                      outlined
                      dense
                      validate-on-blur
                      type='number'
                      reverse
                      prefix=':00'
                      :rules='[formRules.time]'
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class="mb-0 text--primary">
                  打席総数
                  <span class="required-label">
                    必須
                  </span>
                </p>
                <p
                  class="mb-0 text--secondary v-messages"
                  style="line-height: 1.5;"
                >
                  店舗の打席総数を半角数字で入力してください。
                </p>
                <v-text-field
                  outlined
                  dense
                  validate-on-blur
                  v-model.number='shop.capacity'
                  type='number'
                  :rules='[formRules.number]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0' v-if='shop.capacity'>
                <p class="mb-0 text--primary">
                  レフティ打席
                </p>
                <p
                  class="mb-0 text--secondary v-messages"
                  style="line-height: 1.5;"
                >
                  レフティのお客様が予約可能な打席を選択してください。
                </p>
                <v-select
                  v-model='shop.leftyPosition'
                  :menu-props='{ top: true, offsetY: true }'
                  :items='positonCapacity'
                  outlined
                  dense
                  multiple
                ></v-select>
              </v-col>
              <!-- <v-col cols='12' class='py-0' v-if='shop.capacity'>
                <p class="mb-0 text--primary">
                  体験枠 確保打席
                </p>
                <p
                  class="mb-0 text--secondary v-messages"
                  style="line-height: 1.5;"
                >
                  体験のみ予約可能な打席です。体験枠確保期限を過ぎると自動的に打席を解放します。
                </p>
                <v-select
                  v-model='shop.keepTrialPosition'
                  :menu-props='{ top: true, offsetY: true }'
                  :items='positonCapacity'
                  outlined
                  dense
                  multiple
                  hint=''
                ></v-select>
              </v-col>
              <v-col cols='12' class='py-0' v-if='shop.capacity && isExistKeepTrialPosition()'>
                <p class="mb-0 text--primary">
                  体験枠 確保期限
                  <span class="required-label">
                    必須
                  </span>
                </p>
                <p
                  class="mb-0 text--secondary v-messages"
                  style="line-height: 1.5;"
                >
                  指定日数前まで体験用の打席を確保します。「0」を指定した場合、当日もレッスンやフリーレンジを受け付けません。半角数字で入力してください。
                </p>
                <v-text-field
                  v-model.number='shop.keepTrialLimit'
                  type='number'
                  outlined
                  dense
                  validate-on-blur
                  reverse
                  :rules='[formRules.trialLimit]'
                  :prefix='shop.keepTrialLimit >= 0 ? "日前まで" : ""'
                ></v-text-field>
              </v-col> -->
              <v-col cols='12' class='py-0' v-if='shop.capacity'>
                <p class="mb-0 text--primary">
                  体験最大枠数
                  <span class="required-label">
                    必須
                  </span>
                </p>
                <p
                  class="mb-0 text--secondary v-messages"
                  style="line-height: 1.5;"
                >
                  1つの時間帯に受付可能な体験予約の最大枠数です。半角数字で入力してください。
                </p>
                <v-text-field
                  v-model.number='shop.trialMaxCount'
                  label=''
                  outlined
                  dense
                  validate-on-blur
                  type='number'
                  :rules='[formRules.lessonToFree]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0' v-if='shop.capacity'>
                <p class="mb-0 text--primary">
                  体験手続き枠数
                  <span class="required-label">
                    必須
                  </span>
                </p>
                <p
                  class="mb-0 text--secondary v-messages"
                  style="line-height: 1.5;"
                >
                  1件以上体験予約が入っている時間帯のレッスン枠をフリーレンジ枠に変更する数です。半角数字で入力してください。
                </p>
                <v-text-field
                  v-model.number='shop.lessonToFree'
                  label=''
                  outlined
                  dense
                  validate-on-blur
                  type='number'
                  :rules='[formRules.lessonToFree]'
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-text class='ma-3'>
        <v-alert
          type='warning'
          icon='mdi-alert-outline'
          text
          dense
          border='left'
          v-if='action === "edit"'
        >
        シフトが登録済みの日付は変更が適用されません。
        </v-alert>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined @click="close" :disabled="loading">
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn color='blue darken-2' dark outlined @click="save" :loading="loading">
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '../util';

export default {
  name: 'shop-dialog',
  props: {
    selectShop: {
      type: Object,
      default() {
        return {};
      },
    },
    action: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    editedIndex: -1,
    shop: {},
    loading: false,
    valid: true,
    // 入力規則
    formRules: {
      required: value => !!value || '必ず入力してください。',
      number: value => /^([1-9]|1[0-9]|20)$/.test(value) || '1 ~ 20 の範囲で入力してください。',
      trialLimit: value => /^([0-9]|1[0-9]|2[0-9]|30)$/.test(value) || '0 ~ 30 の範囲で入力してください。',
      lessonToFree: value => /^([0-9]|1[0-9]|20)$/.test(value) || '0 ~ 20 の範囲で入力してください。',
      time: value => /^([0-9]|1[0-9]|2[0-3])$/.test(value) || '0 ~ 23 の範囲で入力してください。',
      limit_length: value => (value || '').length <= 20 || '20文字以内で入力してください。',
    },
  }),
  computed: {
    actionName() {
      return this.action === 'add' ? '追加' : '編集';
    },
    positonCapacity() {
      let capacity;
      if (this.shop.capacity) {
        capacity = [...Array(parseInt(this.shop.capacity, 10)).keys()].map(i => i + 1);
      }
      return capacity;
    },
  },
  watch: {
    async dialog() {
      this.$emit('input', this.dialog);
    },
    async value() {
      if (this.value) {
        this.shop = Object.assign({}, this.shop, this.selectShop);
        this.shop.viewCloseTime = this.shop.closeTime + 1;
        this.shop.viewHolidayCloseTime = this.shop.holidayCloseTime + 1;
      } else {
        this.shop = {};
      }
      this.dialog = this.value;
    },
    'shop.capacity'(val) {
      if (val && this.shop.leftyPosition) {
        this.shop.leftyPosition = this.shop.leftyPosition.filter(v => v <= val);
      }
      if (val && this.shop.keepTrialPosition) {
        this.shop.keepTrialPosition = this.shop.keepTrialPosition.filter(v => v <= val);
      }
    },
    'shop.keepTrialPosition'(val) {
      if (val && !val.length) {
        this.$delete(this.shop, 'keepTrialLimit');
      }
    },
  },
  methods: {
    isExistKeepTrialPosition() {
      if (!this.shop.keepTrialPosition) return false;
      return this.shop.keepTrialPosition.length > 0;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.shop = {};
        this.editedIndex = -1;
        this.$refs.form.resetValidation();
      });
    },
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.loading = false;
        return;
      }
      this.shop.closeTime = this.shop.viewCloseTime - 1;
      this.shop.holidayCloseTime = this.shop.viewHolidayCloseTime - 1;
      // this.shop.updateBy = this.userInfo.uid;
      if (this.action === 'add') {
        await util.callCloudFunction('addShop', this.shop).then(() => {
          this.$store.commit('snackbar/open', { text: '店舗は正常に登録されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
          this.loading = false;
        }).catch(() => {
          this.$store.commit('snackbar/open', { text: '店舗の登録に失敗しました。', color: 'error' });
          this.loading = false;
        });
      } else {
        await util.callCloudFunction('editShop', this.shop).then(() => {
          this.$store.commit('snackbar/open', { text: '店舗は正常に更新されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
          this.loading = false;
        }).catch(() => {
          this.$store.commit('snackbar/open', { text: '店舗の更新に失敗しました。', color: 'error' });
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-messages__message {
  line-height: 13px !important;
}
</style>
