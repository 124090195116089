<template>
  <div class='pa-6'>
    <v-sheet tile height="60" class="d-flex">
      <h2>店舗管理</h2>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="add"
      >
        <v-icon>mdi-plus</v-icon>
        店舗を追加
      </v-btn>
    </v-sheet>
    <v-data-table
      :headers='headers'
      :items='shops'
      sort-by='name'
      class='elevation-1'
      :items-per-page='15'
      :loading='loading'
      loading-text='読み込み中です'
      no-data-text='対象のデータがありません'
    >
      <template v-slot:item.actions='{ item }'>
        <v-btn color='error' outlined @click='edit(item)'>
          <v-icon small class='mr-2'>mdi-pencil</v-icon>
          編集
        </v-btn>
      </template>
      <template v-slot:item.weekday='{ item }'>
      {{ chageTime(item.openTime) }} ~ {{ chageTime(item.closeTime+1) }}
      </template>
      <template v-slot:item.holiday='{ item }'>
      {{ chageTime(item.holidayOpenTime) }} ~ {{ chageTime(item.holidayCloseTime+1) }}
      </template>
      <template v-slot:item.leftyPosition='{ item }'>
      {{ item.leftyPosition && item.leftyPosition.length ? String(item.leftyPosition) : 'なし'}}
      </template>
      <!-- <template v-slot:item.keepTrialPosition='{ item }'>
      {{ item.keepTrialPosition && item.keepTrialPosition.length ? String(item.keepTrialPosition) : 'なし'}}
      </template>
      <template v-slot:item.keepTrialLimit='{ item }'>
      {{ item.keepTrialLimit ? item.keepTrialLimit + ' 日前まで' : '確保しない'}}
      </template> -->
    </v-data-table>
    <shop-dialog v-model="dialog" @reload="reload" :action="action" :selectShop="selectShop"></shop-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import util from '../util';
import ShopDialog from '../components/ShopDialog';

export default {
  components: {
    ShopDialog,
  },
  data: () => ({
    dialog: false,
    max: 20,
    headers: [
      { text: '店舗名', align: 'start', sortable: true, value: 'name' },
      { text: '平日営業時間', sortable: false, value: 'weekday' },
      { text: '休日営業時間', sortable: false, value: 'holiday' },
      { text: '打席総数', sortable: false, value: 'capacity' },
      { text: '左打席', sortable: false, value: 'leftyPosition' },
      { text: '体験最大枠', sortable: false, value: 'trialMaxCount' },
      // { text: '体験枠 確保打席', sortable: false, value: 'keepTrialPosition' },
      // { text: '体験枠 確保期限', sortable: false, value: 'keepTrialLimit' },
      { text: '体験手続き枠', sortable: false, value: 'lessonToFree' },
      { text: '操作', sortable: false, value: 'actions' },
    ],
    action: '',
    shops: [],
    selectShop: {},
    loading: false,
  }),

  computed: {
    userInfo() {
      return this.$store.getters.user;
    },
  },

  created() {
    this.reload();
  },

  methods: {
    async reload() {
      this.loading = true;
      this.shops = await util.callCloudFunction('getShop');
      this.loading = false;
    },
    chageTime(item) {
      return moment(item, 'H').format('HH:mm');
    },
    edit(item) {
      this.action = 'edit';
      this.selectShop = item;
      this.dialog = true;
    },
    add() {
      this.action = 'add';
      this.selectShop = {};
      this.dialog = true;
    },
  },
};
</script>
